// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-header .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32.4px;
  text-align: left;
  color: var(--Color-Surface-Primary, #fdfdfd);
}
.response-header .bg-blue {
  background: linear-gradient(180deg, #5095fc 0%, #99bbed 100%);
}`, "",{"version":3,"sources":["webpack://./src/components/forms/pages/responseHeader.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,4CAAA;AAAJ;AAEE;EACE,6DAAA;AAAJ","sourcesContent":[".response-header {\n  .title {\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 32.4px;\n    text-align: left;\n    color: var(--Color-Surface-Primary, #fdfdfd);\n  }\n  .bg-blue {\n    background: linear-gradient(180deg, #5095fc 0%, #99bbed 100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

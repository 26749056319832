// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-bar-section{
    background-color: #1d1d1d;
    width:100%;
    display : flex;
    flex-direction: row;
    /* padding: 20px; */
}
.app-bar-herovired-logo-img{
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/appbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,UAAU;IACV,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB","sourcesContent":[".app-bar-section{\n    background-color: #1d1d1d;\n    width:100%;\n    display : flex;\n    flex-direction: row;\n    /* padding: 20px; */\n}\n.app-bar-herovired-logo-img{\n    padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
